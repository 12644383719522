import {
    ref, Ref, reactive, onBeforeUnmount, onMounted
} from 'vue';
import { timeZone } from '@/data';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import { FormData } from '@/components/view/installer/community/community-set-dialog';
import { FormData as PmFormData } from '@/components/view/installer/community/pm-set-dialog';
import { FormData as OfficeFormData } from '@/components/view/office/office-set-dialog';
import HttpRequest from '@/util/axios.config';
import { isOffice } from '@/methods/role';
import { ImportDetail, isStringParam } from './all.type';

const isShowCommunityDialog = ref(false);
const isShowOfficeDialog = ref(false);
const isShowPmDialog = ref(false);
const userDialogOperaType: Ref<OperateType> = ref('add');
const pmDialogOperaType: Ref<OperateType> = ref('add');
const isShowPmTransfer = ref(false);
function isValidFormData(formData: FormData | false): formData is FormData {
    return formData !== false;
}
function isValidOfficeFormData(formData: OfficeFormData | false): formData is OfficeFormData {
    return formData !== false;
}
function getTimeFormat(type: string) {
    let time1;
    let time2;
    switch (type) {
        case '2':
            time1 = 1;
            time2 = 1;
            break;
        case '3':
            time1 = 2;
            time2 = 1;
            break;
        case '4':
            time1 = 1;
            time2 = 3;
            break;
        case '5':
            time1 = 2;
            time2 = 3;
            break;
        case '6':
            time1 = 1;
            time2 = 5;
            break;
        case '7':
            time1 = 2;
            time2 = 5;
            break;
        default:
            time1 = 1;
            time2 = 1;
            break;
    }
    return [time1, time2];
}
function getUserFormData(value: FormData | false = false) {
    let formData: FormData = {
        ID: '',
        NumberOfApt: 20,
        EnableLandline: '1',
        Location: '',
        Street: '',
        City: '',
        PostalCode: '',
        States: '',
        Country: '',
        TimeZone: timeZone.getManageDefaultTimeZone(),
        Time1: 2,
        Time2: 1,
        AptPinType: '0',
        ChargeMode: '1',
        SendExpireEmailType: '1',
        CustomizeForm: 3,
        AccountID: '',
        SendRenew: '0',
        FeaturePlan: '',
        EnableSmartHome: 0,
        IsCommunityCalls: 0,
        IsHaveAccessArea: 1,
        IsNew: '1'
    };
    if (isValidFormData(value)) {
        formData = { ...value };
        const [time1, time2] = getTimeFormat(formData.CustomizeForm.toString());
        formData.Time1 = time1;
        formData.Time2 = time2;
        formData.NumberOfApt = Number(value.NumberOfApt);
        formData.EnableLandline = value.EnableLandline.toString();
    }
    return formData;
}

// 新建办公初始数据
function getOfficeFormData(value: OfficeFormData | false = false) {
    let formData: OfficeFormData = {
        ID: '',
        EnableLandline: '1',
        IsNew: '1',
        FeaturePlan: '0',
        Location: '',
        Street: '',
        City: '',
        PostalCode: '',
        States: '',
        Country: '',
        TimeZone: timeZone.getManageDefaultTimeZone(),
        TimeFormat: 2,
        DateFormat: 1,
        ChargeMode: '1',
        SendExpireEmailType: '1',
        SendRenew: '0',
        CustomizeForm: 3
    };
    if (isValidOfficeFormData(value)) {
        formData = { ...value };
        const [timeFormat, dateFormat] = getTimeFormat(formData.CustomizeForm.toString());
        formData.TimeFormat = timeFormat;
        formData.DateFormat = dateFormat;
        formData.EnableLandline = value.EnableLandline.toString();
    }
    return formData;
}
const formData = ref(getUserFormData());
const officeFormData = ref(getOfficeFormData());

const getProjectData = () => {
    const communityAdd = () => {
        formData.value = getUserFormData();
        userDialogOperaType.value = 'add';
        isShowCommunityDialog.value = true;
    };
    const officeAdd = () => {
        officeFormData.value = getOfficeFormData();
        userDialogOperaType.value = 'add';
        isShowOfficeDialog.value = true;
    };
    const communityEdit = (value: FormData) => {
        formData.value = getUserFormData(value);
        isShowCommunityDialog.value = true;
        userDialogOperaType.value = 'edit';
    };
    const officeEdit = (value: OfficeFormData) => {
        officeFormData.value = getOfficeFormData(value);
        isShowOfficeDialog.value = true;
        userDialogOperaType.value = 'edit';
    };
    const communityRequestData: RequestData = {
        url: 'v3/web/community/project/getAll',
        param: {}
    };
    const officeRequestData: RequestData = {
        url: 'v3/web/office/project/getOfficeList',
        param: {}
    };
    const projectData = {
        community: {
            add: communityAdd,
            edit: communityEdit,
            requestData: communityRequestData
        },
        office: {
            add: officeAdd,
            edit: officeEdit,
            requestData: officeRequestData
        }
    };
    return projectData;
};

function isValidFormData2(pmFormData: PmFormData | false): pmFormData is PmFormData {
    return pmFormData !== false;
}
function getPmFormData(value: PmFormData | false = false) {
    const pmFormData: PmFormData = {
        ID: '',
        FirstName: '',
        LastName: '',
        Email: '',
        Language: Lang
    };
    if (isValidFormData2(value)) {
        const pmInfo = JSON.parse(JSON.stringify(value));
        Object.keys(pmFormData).forEach((item) => {
            const key = item as keyof PmFormData;
            pmFormData[key] = pmInfo[key];
        });
    }
    return pmFormData;
}
const pmFormData = ref(getPmFormData());
function propertyManageAdd() {
    pmFormData.value = getPmFormData();
    pmDialogOperaType.value = 'add';
    isShowPmDialog.value = true;
}
function propertyManageEdit(value: PmFormData) {
    pmFormData.value = getPmFormData(value);
    pmDialogOperaType.value = 'edit';
    isShowPmDialog.value = true;
}

const initData = () => {
    const CommunityHeaders: Array<ListHeaderItem> = [{
        name: 'Location',
        label: WordList.TabelPersonUserInHtmlName,
        headerType: 'customize'
    }, {
        name: 'DeviceCount',
        label: WordList.RDeiviceDeviceCount
    }, {
        name: 'UserCount',
        label: WordList.RDeiviceUserCount
    }, {
        name: 'Works',
        label: WordList.RDeiviceAdministrators
    }];
    const PropertyHeaders: Array<ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'Community',
        label: WordList.ProperAllTextProject
    }];

    const isShowImportTip = ref(false);
    return {
        CommunityHeaders,
        PropertyHeaders,
        isShowCommunityDialog,
        isShowOfficeDialog,
        isShowPmDialog,
        isShowPmTransfer,
        isShowImportTip
    };
};

const updateToList = ref(0);
const importProject = () => {
    const isShowImportTips = ref(false);
    // 保存导入数据详情
    const importDetails: ImportDetail = reactive({
        Details: [],
        Finished: 0
    });
    // 保存成功状态、错误点击detail的项目，离开页面前进行刷新
    const updateImportItemIds: Ref< Array<string> > = ref([]);
    // 保存失败状态导入完成的任务
    const failedImportItemIds: Ref< Array< string > > = ref([]);
    // 获取当前导入详情（社区名、进度、状态、错误信息）
    // 定时获取导入详情
    const interval: Ref<any> = ref(0);
    const getImportDetails = (updateIds: string | false = false) => {
        HttpRequest.get(isOffice()
            ? 'v3/web/office/officeData/getOfficeImportDetails' : 'v3/web/community/communityData/getCommunityImportDetails', [updateIds ? {
            ImportTaskUUIDs: updateIds
        } : {}, false], (
            res: {
                data: ImportDetail;
            }
        ) => {
            importDetails.Details = res.data.Details;
            importDetails.Finished = res.data.Finished;
            // 保存已经成功的状态
            importDetails.Details.forEach((item) => {
                // 已完成且成功
                if (item.Progress === 100 && item.ProgressStatus === 1 && updateImportItemIds.value.indexOf(item.UUID) === -1) {
                    updateToList.value += 1;
                    updateImportItemIds.value.push(item.UUID);
                } else if (
                    item.Progress === 100 && item.ProgressStatus === 0
                    && item.ImportStatus === 1 && failedImportItemIds.value.indexOf(item.UUID) === -1) {
                    updateToList.value += 1;
                    failedImportItemIds.value.push(item.UUID);
                }
            });
            isShowImportTips.value = importDetails.Details.length !== 0;
            localStorage.setItem(isOffice() ? 'officeTask' : 'communityTask', [...new Set(updateImportItemIds.value)].join(';'));
            if (importDetails.Details.length === 0 || importDetails.Finished === importDetails.Details.length) {
                clearInterval(interval.value);
            } else {
                interval.value = setTimeout(() => {
                    getImportDetails();
                }, 2000);
            }
        });
    };
    const getImportDetailsByInterval = () => {
        // 初始更新已经成功、失败点击过详情的导入
        const updateParam = localStorage.getItem(isOffice() ? 'officeTask' : 'communityTask');
        if (isStringParam(updateParam)) {
            getImportDetails(updateParam);
        } else {
            getImportDetails();
        }
        localStorage.removeItem(isOffice() ? 'officeTask' : 'communityTask');
    };

    // 失败任务点击详情，加入下次更新importtask队列
    const updateImportItemId = (uuid: string) => {
        // 检查是否已存在uuid
        if (updateImportItemIds.value.indexOf(uuid) === -1) {
            updateImportItemIds.value.push(uuid);
        }
        localStorage.setItem(isOffice() ? 'officeTask' : 'communityTask', [...new Set(updateImportItemIds.value)].join(';'));
    };

    const pauseSearchImport = (showTip = false) => {
        if (showTip) {
            isShowImportTips.value = false;
        }
        clearInterval(interval.value);
    };

    onMounted(() => {
        getImportDetailsByInterval();
    });
    // 离开当前页面销毁
    onBeforeUnmount(() => {
        pauseSearchImport();
    });

    return {
        importDetails,
        updateImportItemId,
        getImportDetailsByInterval,
        isShowImportTips,
        pauseSearchImport
    };
};

const propertyMangerData = () => {
    const isShowPropertyManagerDialog = ref(false);

    // 控制弹窗切换按钮置灰
    const isNewCommunity = ref(true);

    return {
        isShowPropertyManagerDialog,
        isNewCommunity
    };
};

export default null;
export {
    initData,
    userDialogOperaType,
    formData,
    pmDialogOperaType,
    propertyManageAdd,
    propertyManageEdit,
    pmFormData,
    officeFormData,
    getProjectData,
    importProject,
    updateToList,
    propertyMangerData
};
