import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "import-error-dialog" }
const _hoisted_2 = { class: "import-error-dialog-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_dialog_shell, {
      title: _ctx.$wordList.ProperAllTextImportDetails,
      footerType: "customize",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
    }, {
      default: _withCtx(() => [
        _createVNode("p", _hoisted_2, _toDisplayString(_ctx.$wordList.ProperAllTextImportProjectFailedTips.format(_ctx.details)), 1)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}