
import {
    defineComponent, PropType, ref,
    watch, Ref, onMounted
} from 'vue';
import importProgress from '@/components/view/common/propgress';
import { ImportDetail } from '@/views/community/home/all.type';
import { isElement } from '@/components/common/tip/util';
import ErrorDialog from './error-dialog.vue';

const liHeight = 68;
export default defineComponent({
    components: {
        importProgress,
        ErrorDialog
    },
    props: {
        details: {
            type: Object as PropType< ImportDetail >,
            required: true
        }
    },
    setup(props, { emit }) {
        // 控制显示错误导入相关显示
        const isShowErrorDialog = ref(false);
        const errorDetails = ref('');
        const showError = (error: string, uuid: string) => {
            errorDetails.value = error;
            isShowErrorDialog.value = true;
            emit('updateItemId', uuid);
        };

        // 控制面板展开收起
        const unfold = ref(false);
        const isFailed = ref(false);
        const isSuccess = ref(true);
        const contentHeight = ref(0);
        const ulRef: Ref< HTMLElement | null > = ref(null);
        watch(props.details, () => {
            isSuccess.value = true;
            isFailed.value = false;
            props.details.Details.forEach((item) => {
                // 获取已完成任务中是否存在失败任务
                if (item.Progress === 100 && item.ProgressStatus === 0 && item.ImportStatus === 1) {
                    isFailed.value = true;
                }
                // 判断全部任务是否都为正确
                if (item.Progress !== 100 || item.ProgressStatus === 0) {
                    isSuccess.value = false;
                }
            });
            // 计算当前导入列表高度
            if (isElement(ulRef.value)) {
                contentHeight.value = liHeight * props.details.Details.length;
                if (contentHeight.value > 340) {
                    contentHeight.value = 340;
                }
            }
        }, {
            immediate: true,
            deep: true
        });
        onMounted(() => {
            // 计算当前导入列表高度
            if (isElement(ulRef.value)) {
                contentHeight.value = liHeight * props.details.Details.length;
                if (contentHeight.value > 340) {
                    contentHeight.value = 340;
                }
            }
        });

        return {
            showError,
            errorDetails,
            isShowErrorDialog,
            unfold,
            isFailed,
            isSuccess,
            ulRef,
            contentHeight
        };
    }
});
