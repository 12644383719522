
import dialogShell from '@/components/common/dialog-shell/index.vue';
import { defineComponent, Ref, ref } from 'vue';
import HttpRequest from '@/util/axios.config';
import moveBuild from '@/components/view/installer/device-set-dialog/moveBuild.vue';
import { communityApi } from '@/api';
import { TransferData, AllWorkData } from './all.type';

export default defineComponent({
    components: {
        dialogShell,
        moveBuild
    },
    props: {
        communityID: {
            type: String,
            required: true
        }
    },
    setup(props, { emit }) {
        const chooseBuilds: Ref<Array<TransferData>> = ref([]);
        const unchooseBuilds: Ref<Array<TransferData>> = ref([]);
        HttpRequest.get('v3/web/common/manage/getSelfPM', { CommunityID: props.communityID }, (res: {data: Record<string, any>}) => {
            res.data.AllWorks.forEach((item: AllWorkData) => {
                if (!res.data.SelectedWorks.includes(item.ID)) {
                    unchooseBuilds.value.push({
                        key: item.ID,
                        label: item.Name
                    });
                } else {
                    chooseBuilds.value.push({
                        key: item.ID,
                        label: item.Name
                    });
                }
            });
        });
        const submit = () => {
            const IDs: Ref<Array<string>> = ref([]);
            chooseBuilds.value.forEach((item: TransferData) => {
                IDs.value.push(item.key);
            });
            communityApi.changeComPerManage({
                IDs: IDs.value.join(';'),
                CommunityID: props.communityID
            }, () => {
                emit('close');
                emit('success');
            });
        };
        return {
            submit,
            chooseBuilds,
            unchooseBuilds
        };
    }
});
