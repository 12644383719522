
import {
    defineComponent,
    PropType,
    Ref,
    ref
} from 'vue';
import { ChooseBuildType } from './all.type';

export default defineComponent({
    props: {
        choose: {
            type: Array as PropType<Array<ChooseBuildType>>,
            default: () => []
        },
        unchoose: {
            type: Array as PropType<Array<ChooseBuildType>>,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const initChooseList = ref(props.choose);
        const initUnchooseList = ref(props.unchoose);

        const checkedRight: Ref<{
            [key in string]: boolean
        }> = ref({});
        const checkedLeft: Ref<{
            [key in string]: boolean
        }> = ref({});
        const changCheckdRight = (item: ChooseBuildType) => {
            checkedRight.value[item.key] = !checkedRight.value[item.key];
        };
        const toRight = () => {
            for (let i = 0; i < initUnchooseList.value.length; i += 1) {
                if (checkedLeft.value[initUnchooseList.value[i].key]) {
                    initChooseList.value.push(initUnchooseList.value[i]);
                    initUnchooseList.value.splice(i, 1);
                    i -= 1;
                }
            }
            checkedLeft.value = {};
            emit('change', initChooseList.value);
        };
        const changCheckdLeft = (item: ChooseBuildType) => {
            checkedLeft.value[item.key] = !checkedLeft.value[item.key];
        };
        const toLeft = () => {
            for (let i = 0; i < initChooseList.value.length; i += 1) {
                if (checkedRight.value[initChooseList.value[i].key]) {
                    initUnchooseList.value.push(initChooseList.value[i]);
                    initChooseList.value.splice(i, 1);
                    i -= 1;
                }
            }
            checkedRight.value = {};
            emit('change', initChooseList.value);
        };
        return {
            initChooseList,
            initUnchooseList,
            changCheckdRight,
            changCheckdLeft,
            checkedRight,
            checkedLeft,
            toRight,
            toLeft
        };
    }
});
