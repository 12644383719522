
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['close'],
    props: {
        details: {
            type: String,
            required: true
        }
    }
});
