import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_move_build = _resolveComponent("move-build")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.TabelUserInHtmlRoleManage,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit,
    width: "750px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_move_build, {
        choose: _ctx.chooseBuilds,
        unchoose: _ctx.unchooseBuilds
      }, null, 8, ["choose", "unchoose"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}