
import { defineComponent, ref, watch } from 'vue';
import { ListHeaderItem, list, RequestData } from '@/components/common/list';
import { communityApi } from '@/api';
import { GetPmAppStatus, ResetPmPasswd } from '@/api/community/all.type';
import Notice from '@/util/notice.base';
import { community } from '@/data';

export default defineComponent({
    emits: ['edit', 'close'],
    components: {
        list
    },
    props: {
        communityID: {
            type: String,
            required: true
        },
        isNew: {
            type: Boolean,
            default: true
        },
        update: {
            type: Number
        }
    },
    setup(props) {
        const propertyHeaders: ListHeaderItem[] = [{
            name: 'Name',
            label: WordList.TabelPersonUserInHtmlName,
            headerType: 'customize',
            type: 'customize'
        }, {
            name: 'PersonalAccount',
            label: WordList.AppUsername
        }, {
            name: 'Email',
            label: WordList.TabelPropertyManageEmail
        }, {
            name: 'ExpireTime',
            label: WordList.TabelExpretimeBoxSpecificTime
        }, {
            name: 'AppStatus',
            label: WordList.NavInHtmlMenuApp,
            type: 'customize'
        }, {
            name: 'Action',
            label: WordList.CaptureListTanleAction,
            type: 'customize'
        }];

        const requestData: RequestData = {
            url: 'v3/web/common/account/getPmInfoList',
            param: {
                CommunityID: props.communityID
            }
        };

        const updateList = ref(0);

        const isDisabled = ref(!props.isNew);

        const changeAppStatus = (val: GetPmAppStatus) => {
            // 切换成功后重新请求列表
            communityApi.changePmAppStatus({
                ID: val.ID,
                CommunityID: props.communityID,
                AppStatus: val.AppStatus
            }, () => {
                updateList.value += 1;
            }, [(res: {
                msg: string;
            }) => {
                Notice.message(res.msg, 'error', false, {
                    onClose: () => {
                        updateList.value += 1;
                    }
                });
            }, false]);
        };

        // 重置密码判断状态开关是否开启，未开启提示先启用pm app
        const resetPasswd = (data: {
            AppStatus: string;
            ID: string;
        }) => {
            if (data.AppStatus === community.statusOff) {
                Notice.messageBox('alert', WordList.EnablePmApp, WordList.Notice, 'warning')(() => false);
            } else {
                const resetParams: ResetPmPasswd = {
                    ID: data.ID,
                    CommunityID: props.communityID
                };
                communityApi.resetPmPasswd(resetParams, () => false);
            }
        };

        watch(() => props.update, () => {
            updateList.value += 1;
        });

        return {
            propertyHeaders,
            requestData,
            updateList,
            isDisabled,
            changeAppStatus,
            resetPasswd
        };
    }
});
