
import {
    defineComponent, PropType,
    reactive, ref, Ref,
    computed
} from 'vue';
import {
    timeZone,
    community, office
} from '@/data';
import InstructionsDialog from '@/components/view/installer/community/read-instructions-dialog';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';
import { FormData } from './all.type';
import {
    addFeaturePlanData, rules, submitUrls
} from './util';

export default defineComponent({
    emits: ['close', 'success', 'featureSuccess'],
    props: {
        operaType: {
            type: String as PropType< OperateType >,
            default: 'add'
        },
        initForm: {
            type: Object as PropType< FormData >,
            required: true
        }
    },
    components: {
        InstructionsDialog
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.ProperAllTextNewOffice,
            edit: WordList.EditOffice
        };
        const form: Ref<any> = ref(null);
        const showChargeMode = computed(() => community.ChargeMode.value);
        // 保存外部传入基础信息
        const formData = reactive({ ...props.initForm });

        // 新增时操作高级功能方案
        const { isShowInstructionsDialog, featurePlanData } = addFeaturePlanData(formData, props.operaType);

        const limitStreetBottom = ref(true);
        const streetBlur = () => {
            if (formData.Street && formData.Street !== '') {
                limitStreetBottom.value = true;
            } else {
                limitStreetBottom.value = false;
            }
        };

        const limitStateBottom = ref(true);
        const stateBlur = () => {
            if (formData.States && formData.States !== '') {
                limitStateBottom.value = true;
            } else {
                limitStateBottom.value = false;
            }
        };

        // 控制postCode样式问题
        const postClass = ref('');
        const postcodeBlur = () => {
            if (formData.PostalCode !== '' && formData.PostalCode.replace(/\s*/g, '') === '') {
                postClass.value = 'post-code';
            } else {
                postClass.value = '';
            }
        };

        // 仅编辑办公时下出现删除按钮
        const deleteOffice = () => {
            noticeBase.messageBox('confirm', WordList.DeleteOfficeTips,
                WordList.TabelDeleteTitle, 'warning')(() => {
                office.deleteOfficeItem(formData.ID, (res: {
                    token: string;
                    msg: string;
                }) => {
                    if (sessionStorage.getItem('token')) {
                        sessionStorage.setItem('token', res.token);
                    } else {
                        localStorage.setItem('token', res.token);
                    }
                    emit('close');
                    window.location.reload();
                });
            });
        };

        // 提交创建办公信息
        const submit = () => {
            streetBlur();
            stateBlur();
            postcodeBlur();
            formData.CustomizeForm = formData.TimeFormat + formData.DateFormat;
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post(submitUrls[props.operaType], formData, () => {
                    emit('close');
                    window.location.reload();
                });
                return true;
            });
        };

        const countryOption = addressOption;
        const provinceDisabled = ref(true);
        const cityDisabled = ref(true);
        const provinceOption = ref<{
            label: string;
            value: string;
            city: {
                label: string;
                value: string;
            }[];
        }[]>([]);
        const cityOption = ref<{
            label: string;
            value: string;
        }[]>([]);

        let isFirst = true;
        function changeProvinceOption(val: string) {
            // 清空省份 城市
            if (props.operaType === 'add' || (!isFirst && props.operaType === 'edit')) {
                formData.States = '';
                formData.City = '';
            }

            // 旧数据匹配不上情况
            const country = countryOption.find((item) => item.value === val);
            // eslint-disable-next-line no-nested-ternary
            provinceOption.value = val ? country ? country!.province : [] : [];
            cityOption.value = [];
            if (provinceOption.value.length === 1 && provinceOption.value[0].value === '') {
                // 没有省份的 但有城市的情况
                provinceDisabled.value = true;
                cityDisabled.value = false;
                changeCityOption('', val);
                rules.value.States = [];
            } else if (provinceOption.value.length === 0) {
                // 没有数据 代表国家没有选
                provinceDisabled.value = true;
                cityDisabled.value = true;
            } else {
                provinceDisabled.value = false;
                cityDisabled.value = true;
                rules.value.States = [
                    {
                        required: true,
                        message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesState),
                        trigger: 'blur'
                    }
                ];
            }
        }

        function changeCityOption(val: string, country: string = formData.Country) {
            if (props.operaType === 'add' || (!isFirst && props.operaType === 'edit')) {
                formData.City = '';
            }
            // 旧数据匹配不上情况
            const countryOp = countryOption.find((item) => item.value === country);
            // eslint-disable-next-line no-nested-ternary
            const province = country ? countryOp ? countryOp!.province : [] : [];

            const state = province.find((states) => states.value === val);
            cityOption.value = state ? state!.city : [];
            cityDisabled.value = !(cityOption.value.length > 0);
        }

        if (props.operaType === 'edit') {
            // 如果没有找到国家 把国家数据置空，要求用户重新选择
            // if (!countryOption.find((item) => item.value === formData.Country)) {
            //     formData.Country = '';
            // }
            changeProvinceOption(formData.Country);
            changeCityOption(formData.States);
            isFirst = false;
        }
        return {
            form,
            showChargeMode,
            formData,
            isShowInstructionsDialog,
            featurePlanData,
            limitStreetBottom,
            limitStateBottom,
            streetBlur,
            stateBlur,
            deleteOffice,
            submit,
            rules,
            timeZone,
            titles,
            postcodeBlur,
            postClass,
            countryOption,
            provinceOption,
            cityOption,
            changeProvinceOption,
            changeCityOption,
            provinceDisabled,
            cityDisabled
        };
    }
});
