import {
    reactive, ref, Ref
} from 'vue';
import { SelectItem } from '@/components/common/select-list';

import router, {
    installerBaseRouter,
    installerSubRouter,
    distributorBaseRouter,
    distributorSubRouter, installerBatchRouter
} from '@/router';
import HttpRequest from '@/util/axios.config';
import { ChangeWordList } from '@/methods/setText';

interface FormData {
    key: string;
    label: string;
}

interface ComFormData {
    Account: string;
    Location: string;
}

const single = '0';

const installerRole = reactive({
    ID: '22',
    label: WordList.TabelUserInHtmlRoleInstall
});

const isShowCommunityList = ref(false);
const getInstaller = (loginType: string | null) => {
    let installer = '';
    if (loginType === single || loginType === null) {
        installer = WordList.TabelUserInHtmlRoleInstall;
        isShowCommunityList.value = false;
    } else {
        installer = WordList.ProperAllTextProjectManager;
        isShowCommunityList.value = true;
    }
    return installer;
};

const getUserOperaData = () => {
    const userOperaList = ref([{
        label: WordList.TabelChangePwTitle,
        key: 'password'
    }, {
        label: WordList.TabelCommunitiesTimeZone,
        key: 'timezone'
    }, {
        label: WordList.OrderBillingInformation,
        key: 'bill'
    }, {
        label: WordList.ProperAllTextCustomerService,
        key: 'customer'
    }, {
        label: WordList.NavInHtmlMenuOrder,
        key: 'payments'
    }]);

    // dis切换时多出切换身份选择
    const userOperaListGrade = ref([{
        label: WordList.TabelChangePwTitle,
        key: 'password'
    }, {
        label: WordList.TabelCommunitiesTimeZone,
        key: 'timezone'
    }, {
        label: WordList.OrderBillingInformation,
        key: 'bill'
    }, {
        label: WordList.ProperAllTextCustomerService,
        key: 'customer'
    }, {
        label: WordList.ProperAllTextChangeIdentity,
        key: 'identity'
    }]);

    return {
        userOperaListGrade,
        userOperaList
    };
};
const getSingleMenuList = (changeMode: string | number = '0') => {
    let menu = [{
        label: WordList.TabelPersonKeyInHtmlUser,
        name: `${installerBaseRouter}_${installerSubRouter.user}|${installerBaseRouter}_${installerSubRouter.familymember}|${
            installerBaseRouter}_${installerSubRouter.userdevice}|${installerBaseRouter}_${installerSubRouter.devicedetail}`,
        path: `/${installerBaseRouter}/${installerSubRouter.user}`,
        icon: 'el-icon-my-user'
    },
    {
        label: WordList.TabelUpdateBoxDevice,
        name: `${installerBaseRouter}_${installerSubRouter.device}`,
        path: `/${installerBaseRouter}/${installerSubRouter.device}`,
        icon: 'el-icon-my-devices2'
    },
    {
        label: WordList.NavInHtmlMenuFirmware,
        name: `${installerBaseRouter}_${installerSubRouter.firmWare}`,
        path: `/${installerBaseRouter}/${installerSubRouter.firmWare}`,
        icon: 'el-icon-my-firmware'
    },
    {
        label: WordList.Upgrade,
        name: `${installerBaseRouter}_${installerSubRouter.update}`,
        path: `/${installerBaseRouter}/${installerSubRouter.update}`,
        icon: 'el-icon-my-update'
    },
    {
        label: WordList.TabelMessageBoxMessage,
        name: `${installerBaseRouter}_${installerSubRouter.message}`,
        path: `/${installerBaseRouter}/${installerSubRouter.message}`,
        icon: 'el-icon-my-group-message'
    },
    {
        label: WordList.NavInHtmlMenuMacLibrary,
        name: `${installerBaseRouter}_${installerSubRouter.library}`,
        path: `/${installerBaseRouter}/${installerSubRouter.library}`,
        icon: 'el-icon-my-mac-library'
    },
    {
        label: WordList.NavInHtmlMenuSet,
        name: `${installerBaseRouter}_${installerSubRouter.setting}`,
        path: `/${installerBaseRouter}/${installerSubRouter.setting}`,
        icon: 'el-icon-my-settings'
    },
    {
        label: WordList.NavInHtmlMenuPurchase,
        name: `${installerBaseRouter}_${installerSubRouter.subscriptions}`,
        path: `/${installerBaseRouter}/${installerSubRouter.subscriptions}`,
        icon: 'el-icon-my-subscription2'
    },
    {
        label: WordList.NavInHtmlMenuOrder,
        name: `${installerBaseRouter}_${installerSubRouter.payments}`,
        path: `/${installerBaseRouter}/${installerSubRouter.payments}`,
        icon: 'el-icon-my-payments'
    }];
    // 6.5.2dis免密需求：dis登录ins隐藏payment、subscription
    if (Number(changeMode) === 1 || sessionStorage.getItem('token')) {
        menu = menu.filter((item) => (item.path !== `/${installerBaseRouter}/${installerSubRouter.subscriptions}`
            && item.path !== `/${installerBaseRouter}/${installerSubRouter.payments}`
            && item.path !== `/${installerBaseRouter}/${installerSubRouter.setting}`));
    }
    return menu;
};

const SubSingleMenu = () => {
    const submain = {
        label: WordList.ProperAllTextToolbox,
        icon: 'el-icon-my-support'
    };
    const submenus = [{
        label: WordList.KnowledgeBase,
        name: 'knowledgeBase',
        path: 'https://knowledge.akuvox.com/'
    }, {
        label: WordList.Academy,
        name: 'academy',
        path: 'http://www.akuvox.com/SupportLearningCertification.aspx'
    }, {
        label: WordList.TechnicalSupport,
        name: 'technicalSupport',
        path: 'https://www.akuvox.com/Support/technical-support/'
    }, {
        label: WordList.ProperAllCommunity,
        name: 'community',
        path: 'http://community.akuvox.com/portal.php'
    }, {
        label: WordList.ProperAllTextCap,
        name: 'cap',
        path: 'http://www.akuvox.com/PartnersChannel.aspx'
    }];
    return {
        submain,
        submenus
    };
};

const initHeader = () => {
    const isShowCouponDetails = ref(false);
    const isShowPasswordDialog = ref(false);
    const isShowTimeZoneDialog = ref(false);
    const isShowBillingDialog = ref(false);
    const isShowCustomerDialog = ref(false);
    const accountName = String(sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : localStorage.getItem('userName'));
    const {
        userOperaList,
        userOperaListGrade
    } = getUserOperaData();
    const communityName: Ref<string> = ref('');
    communityName.value = ChangeWordList.ProjectListTitle;
    return {
        isShowCouponDetails,
        isShowPasswordDialog,
        isShowTimeZoneDialog,
        isShowBillingDialog,
        isShowCustomerDialog,
        accountName,
        userOperaList,
        userOperaListGrade,
        communityName
    };
};

const initGrade = () => {
    const grade = ref(Number(localStorage.getItem('grade')));
    // 不显示社区列表
    // if (grade.value === account.installerRole) {
    //     isShowCommunityList.value = false;
    // }

    // 单住户切换回dis
    const changeGradeDis = () => {
        HttpRequest.post('v3/web/common/manage/changeAreaIdentity', {}, [(data: {
            Token: string;
            Grade: string;
            Account: string;
            TimeZone: string;
            UserName: string;
        }) => {
            localStorage.setItem('token', data.Token);
            localStorage.setItem('grade', data.Grade);
            localStorage.setItem('account', data.Account);
            localStorage.setItem('userName', data.UserName);
            localStorage.setItem('manageTimeZone', data.TimeZone);
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.communityDevice}`);
        }, false]);
    };

    return {
        grade,
        changeGradeDis
    };
};

const initMenu = (changeMode: string | number = '0') => {
    const menus = ref(getSingleMenuList(changeMode));
    const InstallerType: Array<SelectItem> = [{
        key: '22',
        label: WordList.TabelUserInHtmlRoleInstall
    }, {
        key: '21',
        label: WordList.ProperAllTextProjectManager
    }];
    installerRole.label = getInstaller(localStorage.getItem('InstallerLoginType'));
    menus.value = getSingleMenuList(changeMode);
    return {
        menus,
        InstallerType,
        installerRole
    };
};

interface ControlType {
    role: boolean;
    community: boolean;
    account: boolean;
}

const controlPop = () => {
    const controlPopVal: Ref<ControlType> = ref({
        role: false,
        community: false,
        account: false
    });
    const hideAllPop = () => {
        controlPopVal.value.role = false;
        controlPopVal.value.account = false;
        controlPopVal.value.community = false;
    };
    const hideOtherPop = (event: boolean, type: keyof ControlType) => {
        controlPopVal.value[type] = event;
        if (event) {
            Object.keys(controlPopVal.value).forEach((key) => {
                const itemKey = key as keyof ControlType;
                if (itemKey !== type) {
                    controlPopVal.value[itemKey] = false;
                }
            });
        }
    };
    return {
        hideAllPop,
        hideOtherPop,
        controlPopVal
    };
};

const projectId = ref('');
const projectType = ref('');
const batchMenu = () => {
    const menu = [
        {
            label: WordList.NavInHtmlMenuDashboard,
            name: `${installerBaseRouter}_${installerSubRouter.home}`,
            path: `/${installerBaseRouter}/${installerSubRouter.home}`,
            icon: 'el-icon-my-dashboard'
        },
        {
            label: WordList.NavInHtmlMenuPurchase,
            name: `${installerBaseRouter}_${installerBatchRouter.batchSubscriptions}`,
            path: `/${installerBaseRouter}/${installerBatchRouter.batchSubscriptions}`,
            icon: 'el-icon-my-subscription2'
        },
        {
            label: WordList.NavInHtmlMenuOrder,
            name: `${installerBaseRouter}_${installerBatchRouter.batchPayments}|${installerBaseRouter}_${installerBatchRouter.batchPaymentInfo}`,
            path: `/${installerBaseRouter}/${installerBatchRouter.batchPayments}`,
            icon: 'el-icon-my-payments'
        }
    ];
    const routeData = router.currentRoute.value;
    if (
        routeData.name === `${installerBaseRouter}_${installerBatchRouter.batchPayments}`
    ) {
        if (routeData.query.id) {
            projectId.value = routeData.query.id as string;
            projectType.value = routeData.query.type as string;
            menu[2].path = `/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=${routeData.query.type}&id=${routeData.query.id}`;
        } else {
            projectId.value = '';
            projectType.value = '';
        }
    } else if (projectId.value) {
        menu[2].path = `/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=${projectType.value}&id=${projectId.value}`;
    }

    return menu;
};

export default null;
export {
    FormData,
    ComFormData,
    getInstaller,
    getUserOperaData,
    getSingleMenuList,
    initHeader,
    initMenu,
    initGrade,
    SubSingleMenu,
    isShowCommunityList,
    controlPop,
    projectId,
    projectType,
    batchMenu
};
