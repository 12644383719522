import { ref } from 'vue';
import { featurePlan } from '@/data';
import { setting as postalCodeRule } from '@/methods/rule';
import { FeaturePlanData } from '@/components/view/installer/community/read-instructions-dialog/all.type';
import { FormData } from './all.type';

const addFeaturePlanData = (
    formData: FormData,
    operaType: OperateType
) => {
    const initFormData = ref(formData);
    const featurePlanData = ref<Array<FeaturePlanData>>([]);

    // 控制介绍高级功能方案弹窗显示
    const isShowInstructionsDialog = ref(false);

    // 请求dis已有方案
    featurePlan.getDisFeaturePlan({ Type: '1' }, (res: Array<FeaturePlanData>) => {
        if (operaType === 'add') initFormData.value.FeaturePlan = res[0].ID as string;
        featurePlanData.value = res;
    });

    return {
        featurePlanData,
        isShowInstructionsDialog
    };
};

const rules = ref({
    Location: [
        {
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.OfficeName),
            trigger: 'blur'
        }
    ],
    Street: [
        {
            required: true,
            message: WordList.PleaseEnterTheFormat.format(WordList.TabelCommunitiesStreet.toLocaleLowerCase()),
            trigger: 'change'
        }, {
            max: 128,
            message: WordList.StreetTooLong,
            trigger: 'change'
        }
    ],
    City: [
        {
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesCity),
            trigger: 'blur'
        }
    ],
    PostalCode: [
        {
            validator: postalCodeRule.onlyNumberAndNotEmpty,
            trigger: 'blur'
        }
    ],
    States: [
        {
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesState),
            trigger: 'blur'
        }
    ],
    Country: [
        {
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextCountryRegion),
            trigger: 'change'
        }
    ]
});
const submitUrls = {
    add: 'v3/web/office/project/addOfficeItem',
    edit: 'v3/web/office/project/editOfficeItem'
};

export default null;
export {
    addFeaturePlanData,
    rules,
    submitUrls
};