
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: String,
            required: true
        },
        subName: {
            type: String,
            default: ''
        }
    }
});
