
import {
    defineComponent, Ref, ref,
    PropType, computed, watch
} from 'vue';
import itemTitle from '@/components/view/common/dashboard/ItemTitle.vue';
import { list, RequestData, ListActionItem } from '@/components/common/list';
import PropertyManagerDialog from '@/components/view/community/property-manager-dialog';
import communitySetDialog from '@/components/view/installer/community/community-set-dialog';
import officeSetDialog from '@/components/view/office/office-set-dialog';
import importTip from '@/components/view/common/import-tip';
import pmSetDialog, { FormData as PmFormData } from '@/components/view/installer/community/pm-set-dialog';
import pmTransferDialog from '@/components/view/installer/community/pm-transfer-dialog';
import changeTextByScene, { ChangeWordList } from '@/methods/setText';
import HttpRequest from '@/util/axios.config';
import { account, systemConfig, community } from '@/data';
import remove from '@/methods/remove-func';
import { updateSortField, getInitSortField, getSortImg } from '@/methods/sort-table';
import router, {
    installerBaseRouter,
    communityBaseRouter,
    installerSubRouter,
    communitySubRouter,
    installerBatchRouter
} from '@/router';
import { isOffice } from '@/methods/role';
import { homeApi } from '@/api';
import { ChargeMode } from '@/data/src/community';
import { projectId, projectType as batchProjectType } from '@/views/installer/util';
import { getIsShowCommCalls } from '@/api/community/communityCalls';
import { isCNServer } from '@/util/location';
import {
    initData, userDialogOperaType, formData,
    pmDialogOperaType, propertyManageAdd, propertyManageEdit,
    pmFormData, officeFormData,
    getProjectData, importProject, updateToList,
    propertyMangerData
} from './util';

export default defineComponent({
    components: {
        itemTitle,
        list,
        communitySetDialog,
        pmSetDialog,
        pmTransferDialog,
        officeSetDialog,
        importTip,
        PropertyManagerDialog
    },
    props: {
        type: {
            type: String as PropType<ProjectType>,
            default: 'community'
        }
    },
    setup(props) {
        const {
            importDetails,
            updateImportItemId,
            getImportDetailsByInterval,
            isShowImportTips,
            pauseSearchImport
        } = importProject();
        if (!isOffice()) {
            systemConfig.getSysConfig((res) => {
                systemConfig.sysConfig.EnableSmartHome = res.EnableSmartHome;
            });
        }
        if (isOffice()) {
            router.push(`/${installerBaseRouter}/${installerSubRouter.home}?type=office`);
        }
        // 默认加载社区，监听当前角色
        const addProject: Ref<Function> = ref(() => false);
        const editProject: Ref<Function> = ref(() => false);
        const requestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
            // 渲染时加载当前角色相关接口数据
        const getProjectInitData = () => {
            // if (Object.keys(router.currentRoute.value.query).length === 0) {
            //     localStorage.setItem('grade', '22');
            // }
            const projectType = isOffice() ? 'office' : 'community';
            changeTextByScene();
            const projectData = getProjectData()[projectType];
            addProject.value = projectData.add;
            editProject.value = projectData.edit;
            requestData.value = projectData.requestData;
        };
        getProjectInitData();

        const {
            CommunityHeaders,
            PropertyHeaders,
            isShowCommunityDialog,
            isShowOfficeDialog,
            isShowPmDialog,
            isShowPmTransfer,
            isShowImportTip
        } = initData();
        const PropertyRequestData: Ref<RequestData> = ref({
            url: 'v3/web/common/propertyManage/getList',
            param: {}
        });

        const download = () => {
            if (isOffice()) {
                window.open(`/download/template/Office_Template.xlsx?t=${new Date().getTime()}`);
            } else {
                window.open(isCNServer()
                    ? `/download/template/cn/Community_Template.xlsx?t=${new Date().getTime()}`
                    : `/download/template/Community_Template.xlsx?t=${new Date().getTime()}`);
            }
        };
        const action: Array<ListActionItem> = [{
            type: 'import',
            event: 'importCommunity',
            class: 'el-icon-my-import import-icon',
            title: WordList.RDeiviceImport
        },
        {
            type: 'comedit',
            event: 'editCommunity',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        },
        {
            type: 'propery',
            event: 'getProperty',
            class: 'el-icon-my-propery propery-icon',
            title: WordList.TabelUserInHtmlRoleManage
        },
        {
            type: 'comdetail',
            event: 'getCommunityDetail',
            class: 'el-icon-my-info info-icon',
            title: WordList.PersonuserInfo
        }];
        const file: Ref<any> = ref();
        const form: Ref<any> = ref();
        const fileID: Ref<string> = ref('');
        const setFile = (ID: string) => {
            file.value.click();
            fileID.value = ID;
        };
        const importFile = () => {
            const importFormData = new FormData(form.value);
            importFormData.append(isOffice() ? 'OfficeID' : 'CommunityID', fileID.value);
            isShowImportTip.value = true;
            HttpRequest.post(isOffice() ? 'v3/web/office/officeData/importOffice' : 'v3/web/community/communityData/importcommunity',
                importFormData, () => {
                    updateToList.value += 1;
                    getImportDetailsByInterval();
                });
            file.value.value = '';
        };
        const communityID = ref('');

        // v6.4，modify by lxf，6.1社区后pm app相关。----2022.4.6
        const {
            isShowPropertyManagerDialog,
            isNewCommunity
        } = propertyMangerData();

        const getProperty = (communityInfo: {
            IsNew: string;
            ID: string;
        }) => {
            isNewCommunity.value = communityInfo.IsNew !== '0';
            communityID.value = communityInfo.ID;
            if (isOffice()) {
                isShowPmTransfer.value = true;
            } else {
                isShowPropertyManagerDialog.value = true;
            }
        };
        const changeGrade = (newAccount: string) => {
            HttpRequest.post('v3/web/common/manage/changeProjectIdentity', { Account: newAccount }, [(res: {
                Token: string;
                Grade: string;
            }) => {
                if (sessionStorage.getItem('token')) {
                    sessionStorage.setItem('token', res.Token);
                } else {
                    localStorage.setItem('token', res.Token);
                }
                localStorage.setItem('grade', res.Grade);
                localStorage.setItem('Community', newAccount);
                if (res.Grade === '22') {
                    router.push(`/${installerBaseRouter}/${installerSubRouter.user}`);
                } else {
                    router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}`);
                }
            }, false]);
        };
        const getCommunityInfo = (Account: string) => {
            changeGrade(Account);
        };

        const pmaction: Array<ListActionItem> = [{
            type: 'pmedit',
            event: 'editpm',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        },
        {
            type: 'pmdelete',
            event: 'deletepm',
            class: 'el-icon-delete delete-icon',
            title: WordList.TabelConfigInHtmlDelete
        }];

        const propertyManageDelete = (value: PmFormData) => {
            remove(WordList.TabelDeleteText, () => {
                homeApi.deletePm({
                    ID: value.ID
                }, () => {
                    updateToList.value += 1;
                });
            });
        };

        // 获取社区/办公数量
        const total = ref(0);
        const getProjectCounts = (count: number) => {
            total.value = count;
        };

        // 改变社区、办公场景
        const changeScene = (role: ProjectType) => {
            const grade = role === 'community' ? account.communityRole : account.officeRole;
            localStorage.setItem('grade', String(grade));
            community.isRefreshProjectList.value += 1;
            community.isFirstRefresh.value = true;
            router.push(`/${installerBaseRouter}/${installerSubRouter.home}?type=${role}`);
        };
        watch(() => props.type, () => {
            // 根据type切换初始化排序,到新框架要改掉watch，改为事件触发的
            const initSortRes = getInitSortField(`insHome${props.type}`);
            requestData.value.param.SortField = initSortRes.sortField;
            requestData.value.param.Sort = initSortRes.sort;
            console.log('requestData', JSON.stringify(requestData.value));
            updateToList.value += 1;
        }, { immediate: true });

        // 跳转到批量收费
        const goBatchSubscription = () => {
            projectId.value = '';
            batchProjectType.value = '';
            router.push(`/${installerBaseRouter}/${installerBatchRouter.batchSubscriptions}`);
        };

        // 6.5.2户户通需求：获取super是否开启dis户户通配置
        getIsShowCommCalls({}, (res: {
            IsShowCommunityCalls: number;
        }) => {
            community.isShowComCalls.value = res.IsShowCommunityCalls === 1;
        });

        // 是否显示批量付款
        const isShowBatchPayment = computed(() => (props.type === 'community' && Number(ChargeMode.value) === 0) && !sessionStorage.getItem('token'));

        // 获取排序方式， 更新表格数据
        function getSortRes(table: string, sortField: string) {
            const {
                sort
            } = updateSortField(table, sortField);
            requestData.value.param.SortField = sortField;
            requestData.value.param.Sort = sort;
            updateToList.value += 1;
        }
        return {
            CommunityHeaders,
            PropertyHeaders,
            requestData,
            PropertyRequestData,
            isShowCommunityDialog,
            userDialogOperaType,
            formData,
            updateToList,
            download,
            isShowPmDialog,
            pmDialogOperaType,
            propertyManageAdd,
            propertyManageEdit,
            pmFormData,
            action,
            setFile,
            file,
            form,
            importFile,
            isShowPmTransfer,
            getProperty,
            communityID,
            getCommunityInfo,
            pmaction,
            propertyManageDelete,
            changeScene,
            getProjectCounts,
            isShowOfficeDialog,
            officeFormData,
            ChangeWordList,
            editProject,
            addProject,
            isOffice: isOffice(),
            importDetails,
            updateImportItemId,
            isShowImportTips,
            pauseSearchImport,
            isShowPropertyManagerDialog,
            isNewCommunity,
            goBatchSubscription,
            total,
            chargeMode: ChargeMode,
            isShowBatchPayment,
            getSortImg,
            getSortRes

        };
    }
});
