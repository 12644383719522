
import {
    defineComponent, PropType, ref, Ref, reactive
} from 'vue';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';
import { user } from '@/methods/rule';
import { FormData } from './all.type';

export default defineComponent({
    props: {
        operaType: {
            type: String as PropType<OperateType>,
            required: true
        },
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const titles = {
            add: WordList.TabelPropertyManageTitleAdd,
            edit: WordList.TabelPropertyManageTitleModify
        };
        const urls = {
            add: 'addcompmanage',
            edit: 'v3/web/common/propertyManage/edit'
        };
        const rules = {
            FirstName: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageFirstName), trigger: 'blur' }
            ],
            LastName: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageLastName), trigger: 'blur' }
            ],
            Email: [
                { required: true, message: WordList.RuleEmailEmpty, trigger: 'blur' },
                { validator: user.checkEmail, trigger: 'blur' }
            ]
        };
        const form: Ref<any> = ref(null);
        const formData = reactive({ ...props.initForm });
        const submit = () => {
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post(urls[props.operaType], formData, (data: { msg: string }) => {
                    emit('success');
                    emit('close');
                });
                return true;
            });
        };
        const ResetPw = () => {
            HttpRequest.post('v3/web/common/manage/resetPwd', {
                ID: formData.ID
            }, [(data: { msg: string }) => {
                noticeBase.messageBox('alert', data.msg, WordList.TabelResetPwTitle, 'success')(() => false);
            }, false]);
        };

        return {
            formData,
            submit,
            titles,
            urls,
            rules,
            form,
            langOptions,
            ResetPw
        };
    }
});
