import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_button = _resolveComponent("cus-button")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_circle_tip = _resolveComponent("circle-tip")
  const _component_question_tip = _resolveComponent("question-tip")
  const _component_el_switch = _resolveComponent("el-switch")
  const _component_list = _resolveComponent("list")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.TabelUserInHtmlRoleManage,
    width: "500",
    footerType: "customize",
    onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_cus_button, {
                type: "blue",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$wordList.TabelConfigInHtmlModify), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_list, {
                headers: _ctx.propertyHeaders,
                "show-check": false,
                action: false,
                "request-data": _ctx.requestData,
                update: _ctx.updateList
              }, {
                NameSlotHeader: _withCtx((data) => [
                  _createTextVNode(_toDisplayString(data.scope.label) + " ", 1),
                  _createVNode(_component_question_tip, { placement: "top-start" }, {
                    default: _withCtx(() => [
                      _createVNode("div", null, [
                        _createVNode(_component_circle_tip),
                        _createTextVNode(": " + _toDisplayString(_ctx.$wordList.ProperAllTextNormal), 1)
                      ]),
                      _createVNode("div", null, [
                        _createVNode(_component_circle_tip, { type: "disable" }),
                        _createTextVNode(": " + _toDisplayString(_ctx.$wordList.ProperAllTextInactive), 1)
                      ]),
                      _createVNode("div", null, [
                        _createVNode(_component_circle_tip, { type: "warning" }),
                        _createTextVNode(": " + _toDisplayString(_ctx.$wordList.ProperAllTextExpired), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                NameSlot: _withCtx((data) => [
                  _createTextVNode(_toDisplayString(data.scope.Name) + " ", 1),
                  _createVNode(_component_circle_tip, {
                    class: "margin-left5px",
                    type: ['disable', 'warning', 'success'][Number(data.scope.Active)]
                  }, null, 8, ["type"])
                ]),
                AppStatusSlot: _withCtx((data) => [
                  _createVNode(_component_el_switch, {
                    modelValue: data.scope.AppStatus,
                    "onUpdate:modelValue": ($event: any) => (data.scope.AppStatus = $event),
                    "active-value": "1",
                    "inactive-value": "0",
                    "active-color": "#13ce66",
                    "inactive-color": "#ff4949",
                    disabled: _ctx.isDisabled,
                    onChange: ($event: any) => (_ctx.changeAppStatus(data.scope))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
                ]),
                ActionSlot: _withCtx((data) => [
                  _createVNode(_component_cus_button, {
                    onClick: ($event: any) => (_ctx.resetPasswd(data.scope))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$wordList.ResetAppPassword), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["headers", "request-data", "update"])
            ]),
            _: 1
          }),
          (!_ctx.isNew)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                style: {"color":"rgb(255, 114, 9)"}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$wordList.EnablePmAppService), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}