interface ImportDetail {
    Details: {
        ErrorDetail: string;
        Name: string;
        Progress: number;
        ImportStatus: number;
        ProgressStatus: number;
        UUID: string;
    }[];
    Finished: number;
}

function isStringParam(param: string | null): param is string {
    return !(param === null);
}

export default null;
export {
    ImportDetail,
    isStringParam
};